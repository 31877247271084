import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import StickyBox from 'react-sticky-box';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setLaudar as setLaudarAction } from '../../reducers/actions/laudo';
import axios from 'axios';
import { getHeaders } from '../../request';
import { SttGrid, SttButton, SttHidden, SttLoading, SttTranslate } from '@stt-componentes/core';
import Laudo from './laudo';
import Imagens from './imagens';
import Invalidar from '../exame/invalidar';

const useStyles = makeStyles((theme) => ({
	wrapperBotao: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	modalBody: {
		overflow: 'hidden'
	},
	carregando: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%'
	}
}));

const ConteinerLaudo = (props) => {
	const { strings, exame, idRede, setLaudar, callbackProximoExame, notificar } = props;

	const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;
	const REDES_OCULTAR_IGNORAR_EXAME = global.gConfig.redes_ocultar_ignorar_exame;

	const location = useLocation();
	const classes = useStyles();
	const [contextoFabricalaudo] = useState(location.pathname === '/laudo');

	// Invalidação do exame
	const [invalidar, setInvalidar] = useState(false);

	const voltar = () => {
		// Controle de componentes. Volta para tela anterior
		setLaudar(false);
	};

	const ignorarExame = () => {
		notificar(strings.ignorandoExame);
		// Insere exame ignorado
		axios
			.post(`${DERMATO_API_BASE_URL}/ignorar-exame`, { idExame: exame.exame.id_exame }, { headers: getHeaders(), timeout: 15000 })
			.then((resposta) => {
				// Carrega o próximo exame
				callbackProximoExame();
			})
			.catch((err) => console.log(err));
	};

	return (
		<>
			<div className={classes.carregando}>
				<SttLoading
					open={!exame && !contextoFabricalaudo}
					text={strings.carregandoExame}
				/>
			</div>
			{exame && (
				<>
					<SttGrid
						container
						spacing={3}
					>
						<SttGrid
							item
							xs={12}
							className={classes.wrapperBotao}
						>
							{
								<SttButton
									variant="contained"
									danger={+true}
									onClick={() => setInvalidar(true)}
								>
									{strings.invalidar}
								</SttButton>
							}

							{!contextoFabricalaudo && (
								<SttButton
									type="button"
									variant="outlined"
									color="primary"
									onClick={voltar}
								>
									{strings.voltar}
								</SttButton>
							)}

							{contextoFabricalaudo && !REDES_OCULTAR_IGNORAR_EXAME.includes(idRede) && (
								<SttButton
									type="button"
									variant="outlined"
									color="primary"
									onClick={ignorarExame}
								>
									{strings.proximoExame}
								</SttButton>
							)}
						</SttGrid>
					</SttGrid>
					<SttGrid
						container
						spacing={3}
					>
						<SttHidden mdUp>
							<SttGrid
								item
								xs={12}
							>
								<Imagens idExame={exame.exame.id_exame} />
							</SttGrid>
							<SttGrid
								item
								xs={12}
							>
								<Laudo
									dados={exame}
									callbackProximoExame={callbackProximoExame}
								/>
							</SttGrid>
						</SttHidden>
						<SttHidden only={['xs', 'sm']}>
							<SttGrid
								item
								xs={6}
							>
								<StickyBox>
									<Imagens idExame={exame.exame.id_exame} />
								</StickyBox>
							</SttGrid>
							<SttGrid
								item
								xs={6}
							>
								<Laudo
									dados={exame}
									callbackProximoExame={callbackProximoExame}
								/>
							</SttGrid>
						</SttHidden>
					</SttGrid>
				</>
			)}
			{invalidar && (
				<Invalidar
					invalidar={invalidar}
					setInvalidar={setInvalidar}
					idExame={exame.exame.id_exame}
					idRede={idRede}
					callback={callbackProximoExame}
				/>
			)}
		</>
	);
};

ConteinerLaudo.propTypes = {
	strings: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	callbackProximoExame: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.index.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLaudar: (laudar) => dispatch(setLaudarAction(laudar))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('Laudo')(ConteinerLaudo));
