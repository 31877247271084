import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import axios from 'axios';
import { getHeaders } from '../../request';
import Imagens from '../laudo/imagens';
import { SITUACAO_LAUDO } from './constantes';
import { temPermissaoRBAC, temPermissaoRede } from '../../secutity/acl';
import Priorizar from './priorizar';
import Invalidar from './invalidar';
import TrocarRede from './trocarRede';
import Avaliacao from './it/avaliacao';
import Atendimento from './it/atendimentoPaciente';
import { IT, PERMISSOES, MODALIDADE } from '../../common/Constants';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import {
    SttButton,
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttAlerta,
    SttGrid,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttPrintButton,
    SttNotification,
    SttAlertTitle,
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    notificationContent: {
        display: 'flex',
        flexDirection: 'column'
    },
    notification: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    protocolo: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center'
    },
    fileCopyIcon: {
        fontSize: '1.2em',
        cursor: 'pointer',
        color: '#0A6FB8'
    }
}));

const DadosExame = (props) => {
    const { idExame, user, strings, voltar, imprimirFn, imprimirProtocoloFn, imprimirTermoFn, setAtualizarBusca, ocultarBotoes = false } = props;
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();

    const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;
    const [exame, setExame] = useState(null);
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [textoTooltip, setTextoTooltip] = useState(strings.copiar);

    const [priorizar, setPriorizar] = useState(false);
    const [invalidar, setInvalidar] = useState(false);
    const [trocarRede, setTrocarRede] = useState(false);
    const [avaliar, setAvaliar] = useState(false);
    const [atender, setAtender] = useState(false);
    const [redes, setRedes] = useState([]);
    const [permissaoBtns, setPermissaoBtns] = useState([]);

    useEffect(() => {
        if (id) {
            history.replace('/exames');
            history.push('/exames/visualizar', { exame: Buffer.from(id, 'base64').toString('utf-8') });
        }
    }, []);

    useEffect(() => {
        if (user.perfisRBAC && exame) {
            let rede;
            user.perfisRBAC.forEach(p => {
                p.redes && p.redes.forEach(r => {
                    if (r.id === exame.exame.id_rede_telemedicina) {
                        rede = r;
                    }
                });
            });
            if (rede) {
                setPermissaoBtns([
                    temPermissaoRede(rede[0], PERMISSOES.INVALIDAR_EXAME),
                    temPermissaoRede(rede[0], PERMISSOES.PRIORIZAR_EXAME)
                ]);
            }
        }
    }, [user, exame]);

    useEffect(() => {
        const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
        axios
            .get(`${UTILITARIOS_API_BASE_URL}/rede?modalidade=${MODALIDADE.SIGLA}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setRedes(itens);
                }
            })
            .catch(err => console.log(err));
    }, []);

    useEffect(() => {
        if (idExame) {
            axios
                .get(`${DERMATO_API_BASE_URL}/exame/${idExame}`, { headers: getHeaders() })
                .then((response) => {
                    const { data } = response;
                    setExame(data);
                })
                .catch(err => {
                    const { response } = err;
                    let msg = strings.erroDesconhecido;
                    let arrMensagem = [];
                    if (response) {
                        const { data } = response;
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                    }
                    setMensagemErro(msg);
                    setErro(true);
                });
        }
    }, [idExame]);

    const opcoesAlerta = [{
        title: strings.ok,
        onClick: voltar
    }];

    const imprimirDocumento = (doc, tipo) => {
        let tab = window.open();
        tab.document.write('<p>Aguarde...</p>');
        axios
            .get(`${DERMATO_API_BASE_URL}/imprimir-documento-it/${tipo}/historico/${btoa(doc.id)}`, { headers: getHeaders(), responseType: 'blob' })
            .then((response) => {
                if (response.data) {
                    const fileURL = URL.createObjectURL(response.data);
                    tab.location = fileURL;
                }
            })
            .catch(err => console.log(err));
    }

    return (
        <SttContainer>
            {
                !exame
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                        <SttAlerta
                            open={erro}
                            title={strings.erro}
                            message={mensagemErro}
                            type="error"
                            options={opcoesAlerta}
                            onClose={voltar}
                        />
                    </div>
                    :
                    <>
                        <SttHeading variant="h1" color="primary" align="center">{exame.exame.descricao_exame}</SttHeading>
                        <SttExpansionPanel
                            title={strings.paciente}
                            children={
                                <div>
                                    <SttTextItem key="1" title={strings.nome} content={exame.exame.nome_paciente} />
                                    <SttTextItem key="2" title={strings.dataNascimento} content={exame.exame.data_nascimento_paciente_formatada} />
                                    <SttTextItem key="3" title={strings.sexo} content={exame.exame.sexo_paciente} />
                                    {exame.indicacao && <SttTextItem key="4" title={strings.peso} content={`${exame.indicacao.peso} kg`} />}
                                    {exame.indicacao && <SttTextItem key="5" title={strings.altura} content={`${exame.indicacao.altura} cm`} />}
                                    <SttTextItem key="6" title={strings.idade} content={exame.exame.idade_paciente} />
                                    {exame.exame.cartao_sus_paciente && <SttTextItem key="7" title={strings.cns} content={exame.exame.cartao_sus_paciente} />}
                                </div>
                            }
                        />

                        <SttExpansionPanel
                            title={strings.dadosExame}
                            children={
                                <div>
                                    <SttTextItem key="1" title={strings.modalidade} content={exame.exame.descricao_modalidade} />
                                    <SttTextItem key="2" title={strings.dataExame} content={`${exame.exame.data_exame} ${global.gConfig.sufixo_data_hora}`} />
                                    <div className={classes.protocolo}>
                                        <SttTextItem key="3" title={strings.protocolo} content={exame.exame.protocolo_rctm} />
                                        <Tooltip
                                            title={textoTooltip}
                                            arrow
                                            onClick={() => {
                                                navigator.clipboard.writeText(exame.exame.protocolo_rctm);
                                                setTextoTooltip(strings.protocoloCopiado);
                                            }}
                                            onClose={(e) => setTextoTooltip(strings.copiar)}
                                        >
                                            <FileCopyIcon className={classes.fileCopyIcon} />
                                        </Tooltip>

                                    </div>
                                    <SttTextItem key="4" title={strings.requisicao} content={exame.exame.requisicao} />
                                    <SttTextItem key="5" title={strings.instituicao} content={exame.exame.nome_instituicao} />
                                    <SttTextItem key="6" title={strings.medicoSolicitante} content={exame.exame.nome_medico_solicitante} />
                                    {exame.exame.nome_medico_executor && <SttTextItem key="7" title={strings.medicoExecutor} content={exame.exame.nome_medico_executor} />}
                                </div>
                            }
                        />

                        {
                            (exame.exame.observacao_tecnica || exame.encaminhamento?.resultado_biopsia || exame.indicacao?.observacao) &&
                            <SttExpansionPanel
                                title={strings.outrasInformacoes}
                                children={
                                    <div>
                                        {exame.exame.observacao_tecnica && <SttTextItem key="1" title={strings.observacaoTecnica} content={exame.exame.observacao_tecnica} />}
                                        {exame.encaminhamento?.resultado_biopsia && <SttTextItem key="2" title={strings.resultadoBiopsia} content={exame.encaminhamento.resultado_biopsia} />}
                                        {exame.indicacao?.observacao && <SttTextItem key="3" title={strings.observacao} content={exame.indicacao.observacao} />}
                                    </div>
                                }
                            />
                        }

                        {
                            !exame.exame.valido &&
                            <SttNotification severity="info" className={classes.notification}>
                                <SttAlertTitle>{strings.exameInvalidado}</SttAlertTitle>
                                <div className={classes.notificationContent}>
                                    <span>{`${strings.motivo}: ${exame.exame.motivo_invalidacao}`}</span>
                                    <span>{`${strings.responsavel}: ${exame.exame.responsavel_invalidacao}`}</span>
                                    <span>{`${strings.data}: ${exame.exame.data_invalidacao} ${global.gConfig.sufixo_data_hora}`}</span>
                                </div>
                            </SttNotification>
                        }

                        {
                            temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_LAUDO) && exame.laudo &&
                            <SttExpansionPanel
                                title={strings.laudo}
                                children={
                                    <div dangerouslySetInnerHTML={{ __html: exame.laudo }}></div>
                                }
                            />
                        }
                        {
                            exame.laudo && (exame.it.receitas.length > 0 || exame.it.formularios.length > 0 || exame.it.termos.length > 0 || exame.it.protocolos.length > 0) &&
                            <SttExpansionPanel
                                title={strings.contraRefercia}
                                children={
                                    <div>
                                        {
                                            exame.it.receitas.length > 0 &&
                                            <SttGrid container spacing={3}>
                                                <SttGrid item xs={12}>
                                                    <SttTable>
                                                        <SttTableHead>
                                                            <SttTableRow>
                                                                <SttTableCell>
                                                                    {strings.receitaMedica}
                                                                </SttTableCell>
                                                                <SttTableCell align="right">
                                                                    {strings.imprimir}
                                                                </SttTableCell>
                                                            </SttTableRow>
                                                        </SttTableHead>
                                                        <SttTableBody>
                                                            {
                                                                exame.it.receitas.map((r, i) => (
                                                                    <SttTableRow key={i}>
                                                                        <SttTableCell>
                                                                            {r.data_hora_atendimento}
                                                                        </SttTableCell>
                                                                        <SttTableCell align="right">
                                                                            <SttPrintButton
                                                                                onClick={() => imprimirDocumento(r, IT.DOCUMENTOS.RECEITA)}
                                                                            />
                                                                        </SttTableCell>
                                                                    </SttTableRow>
                                                                ))
                                                            }
                                                        </SttTableBody>
                                                    </SttTable>
                                                </SttGrid>
                                            </SttGrid>
                                        }
                                        {
                                            exame.it.formularios.length > 0 &&
                                            <SttGrid container spacing={3}>
                                                <SttGrid item xs={12}>
                                                    <SttTable>
                                                        <SttTableHead>
                                                            <SttTableRow>
                                                                <SttTableCell>
                                                                    {strings.formularioSolicitacaoMed}
                                                                </SttTableCell>
                                                                <SttTableCell align="right">
                                                                    {strings.imprimir}
                                                                </SttTableCell>
                                                            </SttTableRow>
                                                        </SttTableHead>
                                                        <SttTableBody>
                                                            {
                                                                exame.it.formularios.map((f, i) => (
                                                                    <SttTableRow key={i}>
                                                                        <SttTableCell>
                                                                            {f.data_hora_atendimento}
                                                                        </SttTableCell>
                                                                        <SttTableCell align="right">
                                                                            <SttPrintButton
                                                                                onClick={() => imprimirDocumento(f, IT.DOCUMENTOS.FORMULARIO)}
                                                                            />
                                                                        </SttTableCell>
                                                                    </SttTableRow>
                                                                ))
                                                            }
                                                        </SttTableBody>
                                                    </SttTable>
                                                </SttGrid>
                                            </SttGrid>
                                        }
                                        {
                                            exame.it.termos.length > 0 &&
                                            <SttGrid container spacing={3}>
                                                <SttGrid item xs={12}>
                                                    <SttTable>
                                                        <SttTableHead>
                                                            <SttTableRow>
                                                                <SttTableCell>
                                                                    {strings.termoEsclarecimento}
                                                                </SttTableCell>
                                                                <SttTableCell align="right">
                                                                    {strings.imprimir}
                                                                </SttTableCell>
                                                            </SttTableRow>
                                                        </SttTableHead>
                                                        <SttTableBody>
                                                            {
                                                                exame.it.termos.map((t, i) => (
                                                                    <SttTableRow key={i}>
                                                                        <SttTableCell>
                                                                            {t.data_hora_atendimento}
                                                                        </SttTableCell>
                                                                        <SttTableCell align="right">
                                                                            <SttPrintButton
                                                                                onClick={() => imprimirDocumento(t, IT.DOCUMENTOS.TERMO)}
                                                                            />
                                                                        </SttTableCell>
                                                                    </SttTableRow>
                                                                ))
                                                            }
                                                        </SttTableBody>
                                                    </SttTable>
                                                </SttGrid>
                                            </SttGrid>
                                        }
                                        {
                                            exame.it.protocolos.length > 0 &&
                                            <SttGrid container spacing={3}>
                                                <SttGrid item xs={12}>
                                                    <SttTable>
                                                        <SttTableHead>
                                                            <SttTableRow>
                                                                <SttTableCell>
                                                                    {strings.protocoloMed}
                                                                </SttTableCell>
                                                                <SttTableCell align="right">
                                                                    {strings.imprimir}
                                                                </SttTableCell>
                                                            </SttTableRow>
                                                        </SttTableHead>
                                                        <SttTableBody>
                                                            {
                                                                exame.it.protocolos.map((p, i) => (
                                                                    <SttTableRow key={i}>
                                                                        <SttTableCell>
                                                                            {p.data_hora_atendimento}
                                                                        </SttTableCell>
                                                                        <SttTableCell align="right">
                                                                            <SttPrintButton
                                                                                onClick={() => imprimirDocumento(p, IT.DOCUMENTOS.LAUDO)}
                                                                            />
                                                                        </SttTableCell>
                                                                    </SttTableRow>
                                                                ))
                                                            }
                                                        </SttTableBody>
                                                    </SttTable>
                                                </SttGrid>
                                            </SttGrid>
                                        }
                                    </div>
                                }
                            />
                        }
                        {
                            temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_IMAGEM) &&
                            (
                                (
                                    exame.imagensIncompletas &&
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <SttNotification severity="info">{strings.imagensIndisponiveis}</SttNotification>
                                        </SttGrid>
                                    </SttGrid>

                                ) ||
                                <Imagens idExame={idExame} />
                            )
                        }
                        {
                            exame.exame.valido && !ocultarBotoes &&
                            <SttGrid container spacing={1}>
                                <SttGrid item>
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            imprimirFn(idExame, exame.it.dataHoraAcesso)
                                        }}
                                        nomarginleft
                                    >
                                        {strings.imprimir}
                                    </SttButton>
                                </SttGrid>
                                <SttGrid item>
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        nomarginleft
                                        onClick={() => {
                                            imprimirProtocoloFn({ ...exame.exame, id: exame.exame.id_exame })
                                        }}
                                    >
                                        {strings.protocolo}
                                    </SttButton>
                                </SttGrid>
                                {
                                    (permissaoBtns[1] ||
                                        (permissaoBtns[1] === undefined && temPermissaoRBAC(user, PERMISSOES.PRIORIZAR_EXAME))) &&
                                    exame.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO &&
                                    <SttGrid item>
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            nomarginleft
                                            onClick={() => setPriorizar(true)}
                                        >
                                            {strings.priorizar}
                                        </SttButton>
                                    </SttGrid>
                                }
                                {
                                    (((permissaoBtns[0] ||
                                        (permissaoBtns[0] === undefined && temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME))) &&
                                        exame.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO) ||
                                        (temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME_LAUDADO) &&
                                            exame.exame.situacao_laudo === SITUACAO_LAUDO.COM_LAUDO)) &&
                                    exame.exame.valido &&
                                    <SttGrid item>
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            nomarginleft
                                            onClick={() => setInvalidar(true)}
                                        >
                                            {strings.invalidar}
                                        </SttButton>
                                    </SttGrid>
                                }
                                {
                                    exame.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO && temPermissaoRBAC(user, PERMISSOES.TROCAR_REDE) &&
                                    <SttGrid item>
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            nomarginleft
                                            onClick={() => setTrocarRede(true)}
                                        >
                                            {strings.trocarRede}
                                        </SttButton>
                                    </SttGrid>
                                }
                                {
                                    exame.it && exame.it.permiteAtendimentoPaciente &&
                                    <SttGrid item>
                                        <SttButton
                                            type="button"
                                            variant="contained"
                                            color="secondary"
                                            nomarginleft
                                            onClick={() => setAtender(true)}
                                        >
                                            {strings.contraRefercia}
                                        </SttButton>
                                    </SttGrid>
                                }
                                <SttGrid item>
                                    <SttButton
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        nomarginleft
                                        onClick={() => {
                                            imprimirTermoFn(exame.exame)
                                        }}
                                    >
                                        {strings.imprimirTermo}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        }

                        {
                            (permissaoBtns[1] ||
                                (permissaoBtns[1] === undefined && temPermissaoRBAC(user, PERMISSOES.PRIORIZAR_EXAME))) &&
                            priorizar && exame.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO && exame.exame.valido &&
                            <Priorizar priorizar={priorizar} setPriorizar={setPriorizar} idExame={idExame} idInstituicao={exame.exame.id_instituicao} callback={voltar} />
                        }
                        {
                            (((permissaoBtns[0] ||
                                (permissaoBtns[0] === undefined && temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME))) &&
                                exame.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO) ||
                                (temPermissaoRBAC(user, PERMISSOES.INVALIDAR_EXAME_LAUDADO) &&
                                    exame.exame.situacao_laudo === SITUACAO_LAUDO.COM_LAUDO)) &&
                            invalidar &&
                            exame.exame.valido &&
                            <Invalidar
                                invalidar={invalidar}
                                setInvalidar={setInvalidar}
                                idExame={idExame}
                                idInstituicao={exame.exame.id_instituicao}
                                idModalidade={exame.exame.id_modalidade}
                                callback={voltar} />
                        }
                        {
                            exame.it && exame.it.permiteAvaliacao && avaliar && exame.exame.valido &&
                            <Avaliacao avaliar={avaliar} setAvaliar={setAvaliar} idExame={idExame} dataHoraAcesso={exame.it.dataHoraAcesso} callback={voltar} />
                        }
                        {
                            exame.it && exame.it.permiteAtendimentoPaciente && atender && exame.exame.valido &&
                            <Atendimento atender={atender} setAtender={setAtender} idExame={idExame} />
                        }
                        {
                            exame.exame.situacao_laudo === SITUACAO_LAUDO.SEM_LAUDO && temPermissaoRBAC(user, PERMISSOES.TROCAR_REDE) &&
                            <TrocarRede
                                exame={[idExame]}
                                redes={redes}
                                trocarRede={trocarRede}
                                setTrocarRede={setTrocarRede}
                                redeExameSelecionado={exame.exame.id_rede_telemedicina}
                                setAtualizarBusca={setAtualizarBusca}
                                callback={voltar}
                            />
                        }
                    </>
            }
        </SttContainer>
    )
}

export default DadosExame;