import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import {
    SttContainer,
    SttTranslate,
    SttButton
} from '@stt-componentes/core';
import DadosExame from './dados-exame';

const Visualizacao = (props) => {
    const { user, strings, imprimirFn, imprimirProtocoloFn, imprimirTermoFn } = props;
    const location = useLocation();
    const history = useHistory();

    const [idExame, setIdExame] = useState(null);

    useEffect(() => {
        if (location.state?.exame) {
            setIdExame(location.state.exame);
        }
    }, [location]);

    const voltar = () => {
        history.goBack();
    }

    return (
        <SttContainer>
            <SttButton
                type="button"
                variant="outlined"
                color="primary"
                onClick={voltar}
                nomarginleft="true"
            >
                {strings.voltar}
            </SttButton>
            {
                idExame &&
                <DadosExame
                    idExame={idExame}
                    imprimirFn={imprimirFn}
                    imprimirProtocoloFn={imprimirProtocoloFn}
                    imprimirTermoFn={imprimirTermoFn}
                    strings={strings}
                    user={user}
                    voltar={voltar}
                />
            }
        </SttContainer>
    )

}

Visualizacao.propTypes = {
    strings: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps)(SttTranslate('Exame')(Visualizacao));