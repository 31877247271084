import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setLaudar as setLaudarAction } from '../../reducers/actions/laudo';
import axios from 'axios';
import { getHeaders } from '../../request';
import { SttAlerta, SttTabs, SttLoading, SttTranslate } from '@stt-componentes/core';
import { useMoment } from '../../hooks';
import ContainerLaudo from './containerLaudo';
import HistoricoPaciente from './historicoPaciente';
import { Client } from '@hapi/nes/lib/client';

const useStyles = makeStyles((theme) => ({
	carregando: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		width: '100%'
	},
	tabs: {
		padding: 0
	}
}));

const Index = (props) => {
	const { user, strings, idExame, idRede, setLaudar, callbackProximoExame, callbackExameCarregado, notificar } = props;

	const DERMATO_API_BASE_URL = global.gConfig.url_base_dermato;
	const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;

	const location = useLocation();
	const moment = useMoment();
	const classes = useStyles();
	const [exame, setExame] = useState(null);
	const [abas, setAbas] = useState([]);
	const [abaAtiva, setAbaAtiva] = useState('1');
	const [contextoFabricalaudo] = useState(location.pathname === '/laudo');
	const [atencao, setAtencao] = useState(false);

	useEffect(() => {
		if (idExame) {
			axios
				.get(`${DERMATO_API_BASE_URL}/laudo/${idExame}`, { headers: getHeaders() })
				.then((response) => {
					if (response.data) {
						let { data } = response.data;
						let dataMoment = moment(data.exame.data_nascimento_paciente);
						if (dataMoment.isValid()) {
							var now = moment();
							var dataMomentFormatada = moment(dataMoment, 'DD-MM-YYYY');
							var idade = moment.duration(now.diff(dataMomentFormatada));
							var anos = idade.years();
							var meses = idade.months();
							var dias = idade.days();
							data.exame.data_nascimento_paciente_formatada += ` (${anos} ano(s), ${meses} mes(es) e ${dias} dia(s))`;
						}
						setExame(data);
						if (contextoFabricalaudo) {
							callbackExameCarregado();
						}
					}
				})
				.catch((err) => {
					console.log(err);
					const { response } = err;
					let msg = strings.erroDesconhecido;
					let arrMensagem = [];
					if (response) {
						const { data } = response;
						data.errors.forEach((error) => {
							arrMensagem.push(`- ${error.message}`);
						});
						msg = arrMensagem.join('\n');
					}
				});
		}

		return () => {
			setLaudar(false);
			cancelarEmissao();
		};
	}, []);

	useEffect(() => {
		if (exame) {
			axios
				.get(`${EXAMES_API_BASE_URL}/laudo/historico/${idExame}`, { headers: getHeaders() })
				.then((response) => {
					if (response.data) {
						if (response.data.data.length > 0) {
							let dadosAbas = [];

							// Aba histórico
							dadosAbas.push({
								historico: response.data.data,
								titulo: strings.historico,
								permanente: true,
								setAbas,
								setAbaAtiva,
								strings,
								user,
								conteudo: HistoricoPaciente
							});

							// Aba laudo
							dadosAbas.push({
								exame: exame,
								idRede,
								callbackProximoExame,
								callbackExameCarregado,
								notificar,
								permanente: true,
								setLaudar: setLaudar,
								titulo: strings.laudo,
								conteudo: ContainerLaudo
							});

							setAbas(dadosAbas);
						}
					}
				})
				.catch((err) => console.log(err));
		}
	}, [exame, setAbas]);

	useEffect(() => {
		if (exame) {
			const DERMATO_API_WS_BASE_URL = global.gConfig.ws_url_base_dermato;
			const path = `/laudo-emissao/${exame.exame.id_exame}`;
			const client = new Client(DERMATO_API_WS_BASE_URL);
			client
				.connect()
				.then(() => {
					const handler = (update, flags) => {
						if (update.funcionario !== user.idFuncionario) {
							setAtencao(true);
						}
					};

					console.debug(`Subscribing to ${path}`);
					client.subscribe(path, handler);
				})
				.catch((error) => {
					console.log(error);
				});
			return () => {
				console.debug(`Unsubscribing from ${path}`);
				client.unsubscribe(path, null);
			};
		}
	}, [exame]);

	const cancelarEmissao = () => {
		axios.post(`${DERMATO_API_BASE_URL}/cancelar-laudo`, { idExame }, { headers: getHeaders() }).catch((err) => console.log(err));
	};

	const handleCloseAlerta = () => {
		if (contextoFabricalaudo) {
			callbackProximoExame();
		} else {
			setLaudar(false);
		}
	};

	return (
		<>
			<div className={classes.carregando}>
				<SttLoading
					open={!exame && !contextoFabricalaudo}
					text={strings.carregandoExame}
				/>
			</div>
			{exame &&
				((abas.length > 0 && (
					<SttTabs
						className={classes.tabs}
						abaAtiva={abaAtiva}
						setAbaAtiva={setAbaAtiva}
						setAbas={setAbas}
						abas={abas}
						permanente={true}
						canClose={true}
						handleCloseTab={(indice) => {
							abas.splice(indice, 1);
							setAbaAtiva(abas.length - 1 + '');
						}}
					/>
				)) || (
					<ContainerLaudo
						exame={exame}
						idRede={idRede}
						user={user}
						strings={strings}
						callbackProximoExame={callbackProximoExame}
						callbackExameCarregado={callbackExameCarregado}
						setLaudar={setLaudar}
						notificar={notificar}
					/>
				))}

			<SttAlerta
				open={atencao}
				title={strings.atencao}
				message={strings.laudoEmEmissao}
				type="alert"
				options={[
					{
						title: strings.ok,
						onClick: handleCloseAlerta
					}
				]}
				onClose={handleCloseAlerta}
			/>
		</>
	);
};

Index.propTypes = {
	strings: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	idExame: PropTypes.number.isRequired,
	callbackProximoExame: PropTypes.func,
	callbackExameCarregado: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		user: state.index.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setLaudar: (laudar) => dispatch(setLaudarAction(laudar))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SttTranslate('Laudo')(Index));
